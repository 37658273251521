<template>
    <base-scheda-titolo models-name="utenti"
                        :model="model"
                        model-ref="l'utente"
                        :titolo="model.NOME + ' ' + model.COGNOME"
                        :can-edit="$store.state.authModule.utente.CAN_EDIT_UTENTE"
                        :can-delete="$store.state.authModule.utente.CAN_DELETE_UTENTE"
                        check-text="Elimina anche tutte le sue creazioni (eventi, utenze, clienti, contratti, etc.)"
    >
        <template #pre-titolo>
            <v-tooltip bottom v-if="model.PROFILO === 'CLIENTE'">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="warning" large>fa-lock</v-icon>
                </template>
                <span>
                    {{ 'Utenza vincolata a ' + model.CL_NOME }}
                </span>
            </v-tooltip>
        </template>
    </base-scheda-titolo>
</template>

<script>
    import BaseSchedaTitolo from '@/components/aaaProject/scheda/BaseSchedaTitolo';

    export default {
        name: 'SchedaUtenteTitolo',
        components: {BaseSchedaTitolo},
        props: {model: Object}
    }
</script>

<style scoped>

</style>
