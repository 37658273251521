<template>
    <base-scheda models-name="utenti">

        <template #header="{ model }">
            <scheda-utente-header :model="model"></scheda-utente-header>
        </template>

        <template #titolo="{ model }">
            <scheda-utente-titolo :model="model"></scheda-utente-titolo>
        </template>

        <template #generale="{ model }">
            <scheda-utente-generale :model="model"></scheda-utente-generale>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import SchedaUtenteHeader from '@/components/secure/utenti/components/SchedaUtenteHeader';
    import SchedaUtenteTitolo from '@/components/secure/utenti/components/SchedaUtenteTitolo';
    import SchedaUtenteGenerale from '@/components/secure/utenti/components/SchedaUtenteGenerale';

    export default {
        name: 'SchedaUtente',
        components: {SchedaUtenteGenerale, SchedaUtenteTitolo, SchedaUtenteHeader, BaseScheda}
    }
</script>

<style scoped>

</style>
