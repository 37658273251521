<template>
    <base-scheda-generale :model="model" :items="items">

        <template #password>
            <button-tooltip class="ml-2 px-6"
                            text="RESET"
                            color="primary"
                            :disabled="!$store.state.authModule.utente.CAN_EDIT_UTENTE"
                            :loading="isLoading"
                            tooltip="Click per resettare la password"
                            @click.stop="onResetPassword"
            ></button-tooltip>
        </template>

    </base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import moment from '@/moment';

    export default {
        name: 'SchedaUtenteGenerale',
        components: {ButtonTooltip, BaseSchedaGenerale},
        props: {model: Object},
        data: () => ({
            apiTimer: null,
            isLoading: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            items: function () {
                return [
                    {label: 'Profilo', data: this.model.PROFILO},
                    {label: 'Grado', data: this.model.GRADO},
                    {label: 'Nome', data: this.model.NOME},
                    {label: 'Cognome', data: this.model.COGNOME},
                    {label: 'Email', data: this.model.EMAIL},
                    {label: 'Iscrizione', data: moment.toDate(this.model.CREATOR_TIMESTAMP)},
                    {label: 'Ultimo Accesso', data: this.model.DATA_LOGIN ? moment.toDate(this.model.DATA_LOGIN) : 'MAI'},
                    {
                        label: 'Disattivazione Manuale',
                        data: this.model.DATA_DISATTIVAZIONE ? moment.toDate(this.model.DATA_DISATTIVAZIONE) : 'MAI'
                    },
                    {label: 'Password'},
                    {
                        label: 'Ultimo Cambio Password',
                        data: this.model.DATA_CAMBIO_PSW ? moment.toDate(this.model.DATA_CAMBIO_PSW) : 'MAI'
                    },
                    {label: 'Incarichi Aperti', data: this.model.INC_COUNT},
                    {
                        label: 'Cliente',
                        data: !this.model.CL_NOME ? 'Non associato a un cliente' : this.model.CL_NOME
                    }
                ]
            }
        },
        methods: {
            onResetPassword() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //PUT del reset della password dell'utente
                        _this.$store.dispatch('defaultRequest', {
                            api: 'PUT_UTENTE_PASSWORD',
                            paylod: _this.model.ID_REC,
                            doResponse: () => {

                                _this.$store.dispatch('activeSnackbar', {
                                    color: 'success',
                                    text: 'Password resettata con successo'
                                });

                                _this.model.DATA_CAMBIO_PSW = null;

                            },
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
